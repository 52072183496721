const buildSuffix = (url?: {query?: Record<string, string>, hash?: string}) => {
  const query = url?.query;
  const hash = url?.hash;
  if (!query && !hash) return '';
  const search = query ? `?${new URLSearchParams(query)}` : '';
  return `${search}${hash ? `#${hash}` : ''}`;
};

export const pagesPath = {
  _lang: (lang: string | number) => ({
    "actions": {
      $url: (url?: { hash?: string }) => ({ pathname: '/[lang]/actions' as const, query: { lang }, hash: url?.hash, path: `/${lang}/actions${buildSuffix(url)}` })
    },
    "terms": {
      $url: (url?: { hash?: string }) => ({ pathname: '/[lang]/terms' as const, query: { lang }, hash: url?.hash, path: `/${lang}/terms${buildSuffix(url)}` })
    },
    "organizations": {
      _organizationId: (organizationId: string | number) => ({
        "reservations": {
          _reservationId: (reservationId: string | number) => ({
            "inquiry": {
              _clinicAlias: (clinicAlias: string | number) => ({
                _ccid: (ccid: string | number) => ({
                  $url: (url?: { hash?: string }) => ({ pathname: '/[lang]/organizations/[organizationId]/reservations/[reservationId]/inquiry/[clinicAlias]/[ccid]' as const, query: { lang, organizationId, reservationId, clinicAlias, ccid }, hash: url?.hash, path: `/${lang}/organizations/${organizationId}/reservations/${reservationId}/inquiry/${clinicAlias}/${ccid}${buildSuffix(url)}` })
                })
              }),
              "complete": {
                $url: (url?: { hash?: string }) => ({ pathname: '/[lang]/organizations/[organizationId]/reservations/[reservationId]/inquiry/complete' as const, query: { lang, organizationId, reservationId }, hash: url?.hash, path: `/${lang}/organizations/${organizationId}/reservations/${reservationId}/inquiry/complete${buildSuffix(url)}` })
              }
            },
            $url: (url?: { hash?: string }) => ({ pathname: '/[lang]/organizations/[organizationId]/reservations/[reservationId]' as const, query: { lang, organizationId, reservationId }, hash: url?.hash, path: `/${lang}/organizations/${organizationId}/reservations/${reservationId}${buildSuffix(url)}` })
          }),
          $url: (url?: { hash?: string }) => ({ pathname: '/[lang]/organizations/[organizationId]/reservations' as const, query: { lang, organizationId }, hash: url?.hash, path: `/${lang}/organizations/${organizationId}/reservations${buildSuffix(url)}` })
        },
        "users": {
          "edit": {
            $url: (url?: { hash?: string }) => ({ pathname: '/[lang]/organizations/[organizationId]/users/edit' as const, query: { lang, organizationId }, hash: url?.hash, path: `/${lang}/organizations/${organizationId}/users/edit${buildSuffix(url)}` })
          },
          "leave": {
            $url: (url?: { hash?: string }) => ({ pathname: '/[lang]/organizations/[organizationId]/users/leave' as const, query: { lang, organizationId }, hash: url?.hash, path: `/${lang}/organizations/${organizationId}/users/leave${buildSuffix(url)}` })
          }
        },
        "contact": {
          $url: (url?: { hash?: string }) => ({ pathname: '/[lang]/organizations/[organizationId]/contact' as const, query: { lang, organizationId }, hash: url?.hash, path: `/${lang}/organizations/${organizationId}/contact${buildSuffix(url)}` })
        },
        "forgot_password": {
          $url: (url?: { hash?: string }) => ({ pathname: '/[lang]/organizations/[organizationId]/forgot_password' as const, query: { lang, organizationId }, hash: url?.hash, path: `/${lang}/organizations/${organizationId}/forgot_password${buildSuffix(url)}` })
        },
        "leave": {
          "completed": {
            $url: (url?: { hash?: string }) => ({ pathname: '/[lang]/organizations/[organizationId]/leave/completed' as const, query: { lang, organizationId }, hash: url?.hash, path: `/${lang}/organizations/${organizationId}/leave/completed${buildSuffix(url)}` })
          }
        },
        "login": {
          $url: (url?: { hash?: string }) => ({ pathname: '/[lang]/organizations/[organizationId]/login' as const, query: { lang, organizationId }, hash: url?.hash, path: `/${lang}/organizations/${organizationId}/login${buildSuffix(url)}` })
        },
        "register": {
          $url: (url?: { hash?: string }) => ({ pathname: '/[lang]/organizations/[organizationId]/register' as const, query: { lang, organizationId }, hash: url?.hash, path: `/${lang}/organizations/${organizationId}/register${buildSuffix(url)}` })
        },
        "user": {
          "online_reserve": {
            _clinicAlias: (clinicAlias: string | number) => ({
              $url: (url?: { hash?: string }) => ({ pathname: '/[lang]/organizations/[organizationId]/user/online-reserve/[clinicAlias]' as const, query: { lang, organizationId, clinicAlias }, hash: url?.hash, path: `/${lang}/organizations/${organizationId}/user/online-reserve/${clinicAlias}${buildSuffix(url)}` })
            })
          },
          "reserve": {
            _clinicAlias: (clinicAlias: string | number) => ({
              $url: (url?: { hash?: string }) => ({ pathname: '/[lang]/organizations/[organizationId]/user/reserve/[clinicAlias]' as const, query: { lang, organizationId, clinicAlias }, hash: url?.hash, path: `/${lang}/organizations/${organizationId}/user/reserve/${clinicAlias}${buildSuffix(url)}` })
            })
          },
          $url: (url?: { hash?: string }) => ({ pathname: '/[lang]/organizations/[organizationId]/user' as const, query: { lang, organizationId }, hash: url?.hash, path: `/${lang}/organizations/${organizationId}/user${buildSuffix(url)}` })
        }
      })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/[lang]' as const, query: { lang }, hash: url?.hash, path: `/${lang}${buildSuffix(url)}` })
  })
};

export type PagesPath = typeof pagesPath;
