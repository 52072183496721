import { useParams } from 'next/navigation'

import { ALL_ORGANIZATION_ALIAS } from '@constants/organization'
import { useMemo } from 'react'

export const useOrganizationId = () => {
	const params = useParams()
	const organizationId = useMemo(
		() => (params.organizationId as string) ?? ALL_ORGANIZATION_ALIAS,
		[params.organizationId],
	)

	return organizationId
}
