'use client'
import { useRouter } from 'next/navigation'
import { useEffect } from 'react'

import { ALL_ORGANIZATION_ALIAS } from '@constants/organization'

import { useOrganizationId } from '@hooks/useOrganizationId'

import type { LocaleProps } from '@atnd/utils/types'
import { pagesPath } from '../../utils/$path'

export default function App({ params }: { params: LocaleProps }) {
	const router = useRouter()
	const organizationId = useOrganizationId()

	useEffect(() => {
		router.replace(
			pagesPath
				._lang(params.lang)
				.organizations._organizationId(organizationId ?? ALL_ORGANIZATION_ALIAS)
				.user.$url().path,
		)
	}, [organizationId, params.lang, router])

	return <></>
}
